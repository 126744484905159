const requestPTItems = () => {
  let formData = new FormData();
  formData.append('action', 'requestPTItems');
  const params = new URLSearchParams(formData);

  let options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
    body: params
  };

  fetch(tofinoJS.ajaxUrl, options)
    .then(response => response.json())
    .then(result => {
      let ghostNewsGrid = document.getElementById('ghost-pt-grid')
      ghostNewsGrid.innerHTML = result;

      document.getElementById('ghost-pt-loader').classList.add('hidden')
    })
    .catch(function (error) {
      console.warn('Error', error);
    });
    
};

export default requestPTItems;
