const requestTrainers = () => {
  let formData = new FormData();
  formData.append('action', 'requestTrainers');
  const params = new URLSearchParams(formData);

  let options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
    body: params
  };

  fetch(tofinoJS.ajaxUrl, options)
    .then(response => response.json())
    .then(result => {
      let trainersGrid = document.getElementById('trainers-grid')
      trainersGrid.innerHTML = result;

      document.getElementById('trainers-loader').classList.add('hidden')
    })
    .catch(function (error) {
      console.warn('Error', error);
    });
    
};

export default requestTrainers;
