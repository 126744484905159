import Chart from 'chart.js/auto'

const requestGroupTotals = () => {
  let formData = new FormData();
  formData.append('action', 'requestGroupTotals');
  const params = new URLSearchParams(formData);

  let options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded;' },
    body: params
  };

  fetch(tofinoJS.ajaxUrl, options)
    .then(response => response.json())
    .then(result => {
      
      document.getElementById('total_countries').innerHTML = result.total_countries
      document.getElementById('total_active_groups').innerHTML = result.total_active_groups
      document.getElementById('total_groups').innerHTML = result.total_groups
      document.getElementById('total_people').innerHTML = result.total_people.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      document.getElementById('total_participants').innerHTML = result.total_participants.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      
      document.getElementById('total_active_groups_perc').innerHTML = (result.total_active_groups / result.total_groups * 100).toFixed(1)

      let projects = result.projects
      
      let projectsString = '<ul>'
      
      let chartLabels = [], chartData = []

      //sort array by number of projects
      projects.sort((a, b) => b.number_of_projects - a.number_of_projects)

      projects.forEach((project) => {
        projectsString += '<li>' + project.name + ': <strong>' + project.number_of_projects + '</strong></li>'

        chartLabels.push(project.name)
        chartData.push(project.number_of_projects)
      })

      projectsString += '</ul>'

      document.getElementById('group_projects').innerHTML = projectsString


      //chart 
      const ctx = document.getElementById('projectChart');
      
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: chartLabels,
          datasets: [{
            label: '# of Projects',
            data: chartData,
            backgroundColor: ["#FF0000",
              "#FF8800",
              "#FFFF00",
              "#88FF00",
              "#00FF00",
              "#00FF44",
              "#00FF88",
              "#00FFBB",
              "#00FFFF",
              "#0088FF",
              "#0000FF",
              "#8800FF",
              "#FF00FF",
              "#FF00BB",
              "#FF0088",
              "#FF0044"],
            borderWidth: 2
          }]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
          }
        }
      });

      
    })
    .catch(function (error) {
      console.warn('Error', error);
    });
    
};

export default requestGroupTotals;
