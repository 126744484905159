// Import Alert
import Alert from './modules/alert';

// Menu
import Menu from './modules/menu';

// Import Font loader
import WebFont from 'webfontloader';

import requestNewsItems from  './modules/ajax/news'
import requestResourcesItems from  './modules/ajax/resources'
import requestHumHubSpaces from  './modules/ajax/humhub-spaces'
import requestGroupTotals from  './modules/ajax/group-totals'
import requestITItems from  './modules/ajax/it-items'
import requestPTItems from  './modules/ajax/pt-items'
import requestEvents from  './modules/ajax/events'
import requestTrainers from  './modules/ajax/trainers'
import "bsky-embed/dist/bsky-embed.es.js"

export default {
  init () {
    // JavaScript to be fired on all pages

    // Load Fonts
    WebFont.load({
      classes: false,
      events: false,
      google: {
        families: ['Roboto:wght@300;400;700'],
        display: 'swap',
        version: 2,
      },
    });

    // Alert
    if (document.getElementById('tofino-notification')) {
      Alert();
    }

    // Menu
    if (document.getElementById('menu-primary-navigation')) {
      Menu();
    }

    //Ghost News
    if (document.getElementById('ghost-news-grid')) {
      requestNewsItems();
    }
    
    //Group Totals
    if (document.getElementById('group-totals')) {
      requestGroupTotals();
    }
    
    //Resources Items
    if (document.getElementById('resources-news-grid')) {
      requestResourcesItems();
    }
    
    //Humhub Spaces
    if (document.getElementById('humhub-spaces-grid')) {
      requestHumHubSpaces();
    }
    
    //Inner Transition
    if (document.getElementById('ghost-it-grid')) {
      requestITItems();
    }
    
    //Practising Transition
    if (document.getElementById('ghost-pt-grid')) {
      requestPTItems();
    }

    //Events
    if (document.getElementById('events-grid')) {
      requestEvents();
    }
    
    //Events
    if (document.getElementById('trainers-grid')) {
      requestTrainers();
    }
    
  },
  finalize () {
    // JavaScript to be fired after init
  },
  loaded () {
    // Javascript to be fired once fully loaded
  },
};
